footer {
  background-color: #0f3460;
  padding: 10px 0;
  color: #fff;
}
footer h1 {
  margin-bottom: 30px;
  color: #ff3131;
  font-weight: 800;
  font-style: italic;
}
footer .img {
  background-color: #0c2a4d;
  padding: 15px 20px;
  border-radius: 5px;
}
footer .img i {
  font-size: 20px;
  margin: 5px;
}
footer .img span {
  margin-top: 5px;
}
footer p {
  font-size: 14px;
  font-weight: 300;
  opacity: 0.5;
  margin-bottom: 20px;
}
footer h2 {
  margin-bottom: 20px;
}
footer li {
  margin-bottom: 10px;
  opacity: 0.5;
  color: white;
}

footer .copyright {
  margin-left: 55%;
  font-size: 10px;
  margin-top: 5%;
  /* background: #F7F7F7; */
}

footer hr {
  margin-top: 5%;
}

footer li a{
  margin-bottom: 10px;
  color: white;
}

footer li a:hover{
  margin-bottom: 10px;
  color: #ff014f;
}